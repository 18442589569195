import React from 'react';
import { Arrow, Layer, Rect, Text } from 'react-konva';
import { KonvaImage } from "./image";
import { Colors } from '../configs/colors';

export const HallOne = () => {
    const wallColor = Colors.WALL;
    const wallColorBrown = Colors.WALLDARK;
    const doorColor = Colors.DOORS;
    const colorBlue = '#0ea2ff'; // Rote Farbe für die Türen
    const colorRed = '#de2727'; // Rote Farbe für die Türen
    const colorYellow = '#fff600'; // Rote Farbe für die Türen
    const colorBrown = '#7a5235'; // Rote Farbe für die Türen
    const colorWhite = '#ffffff'; // Rote Farbe für die Türen

    return (
        <Layer>
            {/* Wände */}
            <Rect x={70} y={100} width={800} height={400} fill={wallColor} />

            {/* Türen */}
            <KonvaImage x={200} y={100} width={50} height={30} src={"exit.png"} />
            <KonvaImage x={300} y={100} width={50} height={30} src={"exit.png"} />
            <KonvaImage x={400} y={100} width={50} height={30} src={"exit.png"} />
            <KonvaImage x={500} y={100} width={50} height={30} src={"exit.png"} />

            <Arrow x={225} y={70} points={[0,30, 0, 0]} fill={"brown"} stroke={"brown"} strokeWidth={15} />
            <Arrow x={325} y={70} points={[0,30, 0, 0]} fill={"brown"} stroke={"brown"} strokeWidth={15} />
            <Arrow x={425} y={70} points={[0,30, 0, 0]} fill={"brown"} stroke={"brown"} strokeWidth={15} />
            <Arrow x={525} y={70} points={[0,30, 0, 0]} fill={"brown"} stroke={"brown"} strokeWidth={15} />

            <KonvaImage x={707} y={138} width={30} height={20} src={"exit.png"} />

            <Arrow x={300} y={385} points={[100,0, 0, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={550} y={385} points={[100,0, 0, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={550} y={200} points={[0,0, 100, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={300} y={200} points={[0,0, 100, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={200} y={250} points={[0,100, 0, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={715} y={250} points={[0,0, 0, 100]} fill={"black"} stroke={"black"} />

            <Rect x={725} y={380} width={80} height={35} fill={doorColor} />
            <Text x={740} y={390} text="Eingang" fontSize={15} />

            <Rect x={872} y={490} width={310} height={260} fill={wallColor} />
            <Rect x={1100} y={510} width={30} height={220} fill={colorWhite} cornerRadius={20} />
            <Text x={1106} y={675} text="CATERING" fontSize={20} fill={wallColorBrown} rotationDeg={270} />
            <Rect x={910} y={140} width={260} height={260} fill={wallColorBrown} />

            <Rect x={920} y={400} width={30} height={15} fill={colorBlue} />
            <Rect x={951} y={400} width={30} height={15} fill={colorRed} />
            <Rect x={982} y={400} width={30} height={15} fill={doorColor} />
            <Rect x={1013} y={400} width={30} height={15} fill={colorYellow} />

            <Text x={1091} y={400} text="Einführung" fontSize={15} />
            <Text x={930} y={420} text="Wall of Fame" fontSize={15} />
            <Text x={934} y={435} text="Berufsfelder" fontSize={15} />

            <Rect x={900} y={460} width={30} height={30} fill={colorBrown} />
            <Rect x={931} y={460} width={70} height={30} fill={colorBrown} />
            <KonvaImage x={900} y={460} width={30} height={30} src={"medic.png"} />
            <Text x={943} y={466} text="INFO" fontSize={20} fill={colorWhite} />

            <Rect x={1090} y={390} width={73} height={10} fill={wallColor} />
            <Rect x={1095} y={380} width={63} height={10} fill={wallColor} />
            <Rect x={1100} y={370} width={53} height={10} fill={wallColor} />
            <Rect x={1105} y={360} width={43} height={10} fill={wallColor} />
            <Rect x={1110} y={350} width={33} height={10} fill={wallColor} />
            <Rect x={1115} y={340} width={23} height={10} fill={wallColor} />

            <Arrow x={1128} y={320} points={[0,80, 0, 0]} fill={"black"} stroke={"black"} />

            <KonvaImage x={930} y={270} width={50} height={50} src={"wc.png"} />
            <KonvaImage x={930} y={320} width={50} height={50} src={"wc.png"} />
        </Layer>
    );
};
