
export class Colors {
    static WALL: string = "#ccc";
    static WALLDARK: string = "#b5af9f";
    static DOORS: string = "#1f8003";

    static STAND_BG: string = "red";
    static STAND_BG_INACTIVE: string = "darkred";
    static STAND_BG_HOVER: string = "orange";
    static STAND_BG_INACTIVE_HOVER: string = "red";

    static COMPANY_DETAILS_BG_CAROUSEL: string = "#c6c6cc";
    static COMPANY_DETAILS_BG_POSITION: string = "#182d8e";
    static COMPANY_DETAILS_BG_TEXT: string = "white";
}
