import axios from "axios";


export class API {
    static URL: string = (process.env.NODE_ENV === 'production' ? 'https://api.jobklahr.de' : 'http://localhost:8080');


    static async request<T>(type: 'post' | 'get', url: string, data?: any): Promise<T | undefined> {
        return new Promise<T | undefined>( (resolve) => {
            console.log('[API.REQUEST]', API.URL + url, data);
            axios({
                method: type,
                url: API.URL + url,
                data: data
            }).then( ( result ) => {
                if ( result.data.errors ) {
                    console.error(result.data);
                    resolve(undefined);
                }

                console.log('[API.REQUEST] result:', result.data as T);
                resolve(result.data as T);
            }).catch( ( error ) => {
                console.log(error);
                resolve(undefined);
            });
        });
    }
}
