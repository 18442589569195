import { Carousel, Col, Collapse, Modal, QRCode, Row, Spin, Typography } from "antd";
import { Company } from "../interfaces/company";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Colors } from "../configs/colors";
import {YesNoArray, YesNoEnum} from "../interfaces/yesNo.enum";


interface CompanyDetailsProps {
    company: Company;
    onClose: () => void;
}

export const CompanyDetails = ({company, onClose}: CompanyDetailsProps) => {
    
    const sortCareers = () => {
        const array = company.career;

        /*Todo: make career sort by name or branch? 
        for ( const list of company.career ) {
            filteredList = filteredList.sort(list.name);
            if ( filteredList.length != 0 ) {
                array.push({
                    label: list.name,
                    items: filteredList
                });
            }
        }
        */
        
        return array;
    }

    const getDescription = () => {
        //Todo: replace image with image tag

        if ( !company.description ) return "";

        return company.description.replace(/(<img.*?src=")(.*?)"/g, (match, prefix, src) => {
            //const fileName = src.replace("/media/", "");
            const newSrc = `https://jobklahr.de/${src}`;
            return `${prefix}${newSrc}"`;
        });
    }

    return (<>
        <Modal
            destroyOnClose={true}
            className="right"
            centered={false}
            style={{ top: 20 }}
            open={true}
            title={"Wir haben dich neugierig gemacht?\n" +
                "Hier findest du noch mehr Infos:"}
            width={250}
            footer={[]}
        >
            <Row justify={"center"}>
                <QRCode
                    value={"https://jobklahr.de/index.php?article_id=" + company.article_id}
                    size={200}
                />
            </Row>
        </Modal>
        <Modal
            destroyOnClose={true}
            className="left"
            centered={false}
            style={{ top: 20 }}
            open={true}
            onCancel={onClose}
            onOk={onClose}
            width={980}
            mask={false}
            footer={[]}
        >
            <Row justify="space-between" align={"middle"} wrap={false}>
                <div><Typography.Title level={3}>{company.name}</Typography.Title></div>
                <div>
                    <img
                        src={"https://jobklahr.de/index.php?rex_media_type=full&rex_media_file=" + company.logo}
                        width="auto"
                        style={{marginRight: 10, maxHeight: 150, maxWidth: 250}}
                    />
                </div>
            </Row>

            <div dangerouslySetInnerHTML={ {__html: getDescription()!}}></div>

            <Carousel
                autoplay
                effect="fade"
                pauseOnDotsHover={false}
                pauseOnHover={false}
                dotPosition={"top"}
                style={{backgroundColor: Colors.COMPANY_DETAILS_BG_CAROUSEL, marginBottom: 20, marginTop: 20}}
            >
                { company.galarieImage && company.galarieImage.split(",").map( (imageName: string) => {
                        return <div>
                            <Row justify="center">
                                <LazyLoadImage
                                    alt={"Bild"}
                                    height={"400px"}
                                    src={"https://jobklahr.de/index.php?rex_media_type=full&rex_media_file=" + imageName}
                                    width={"auto"}
                                    placeholder={<Spin tip="Loading" size="small" />}
                                />
                            </Row>
                        </div>
                    })
                }
            </Carousel>

            <Collapse expandIconPosition={"right"}>
                {company.career.map((career, index) => {

                    const barrierValid = career.barrier || "0";
                    const barrier = YesNoArray[Number(barrierValid)];

                    const handicapValid = career.handicap || "0";
                    const handicap = YesNoArray[Number(handicapValid)];


                    return <Collapse.Panel
                        key={index.toString()}
                        header={<span style={{ fontWeight: 'bold' }}>{career.name}</span>}
                    >
                        <div>
                            {career.description!.replaceAll("<br>", "\n")}
                        </div>
                        {career.condition && career.condition.length != 0 &&
                            <div><strong>Voraussetzung:</strong> <span
                                dangerouslySetInnerHTML={{__html: career.condition!}}></span></div>
                        }
                        {barrier && <div><strong>Barrierefreier Arbeitsplatz:</strong> {barrier}</div>}
                        {handicap && <div><strong>Geeignet für Menschen mit Handicap:</strong> {handicap}</div>}
                    </Collapse.Panel>
                })}
            </Collapse>

            <Row justify={"center"} align="middle" style={{ marginTop: 20 }}>
                <div style={{marginRight: 20, fontWeight: 'bold', fontSize: 20}}>STAND: </div>

                <div className="company_position_info" style={{
                    backgroundColor: Colors.COMPANY_DETAILS_BG_POSITION,
                    color: Colors.COMPANY_DETAILS_BG_TEXT
                }}>
                    {company.standNumber}
                </div>
            </Row>
        </Modal>
    </>);
}
