
export enum YesNoEnum {
    NONE,
    NO,
    YES
}

export const YesNoArray: string[] = [
    "Keine Angaben",
    "Nein",
    "Ja"
]