import { useEffect, useState } from "react";
import React from "react";
import { Image } from 'react-konva';

interface KonvaImageProps {
    src: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

export const KonvaImage = ({ src, x, y, width, height }: KonvaImageProps) => {
    const [image, setImage] = useState<CanvasImageSource | null>(null);

    useEffect(() => {
        const loadImage = () => {
            if ( typeof window !== 'undefined' ) {
                const newImage = new window.Image();
                newImage.onload = () => {
                    console.log('image loaded!');
                    setImage(newImage);
                };
                newImage.src = src;
            }
        };

        loadImage();
    }, [src]);

    return <Image x={x} y={y} image={image!} width={width} height={height} />;
};
