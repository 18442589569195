import { Company, CompanyCareer } from "../interfaces/company";
import { Button, Form, Select, Space, Switch, Typography } from "antd";
import React, { useEffect } from "react";
import { FilterModel } from "../models/filter";
import { Branch } from "../interfaces/branch";


interface FilterProps {
    companies: Company[];
    branches: Branch[];
    filter: FilterModel;
    onChange: (filter: FilterModel | undefined) => void;
}

export const Filter = ({companies, onChange, branches, filter}: FilterProps) => {

    useEffect(() => {
        //Todo read branches
        //Todo read angebote
    }, []);

    const onCompanyChange = (value: string[], options: any) => {
        filter.companies = value.map((val) => val.replace("company_", ""));
        onChange(filter);
    }

    const onBranchesChange = (value: string[], options: any) => {
        filter.branches = value.map((val) => val.replace("branch_", ""));
        onChange(filter);
    }

    const onCareerChange = (value: string[], options: any) => {
        filter.careers = value.map((val) => val.replace("career_", ""));
        onChange(filter);
    }

    const onSwitchChange = (type: 'school' | 'educations' | 'studies', value: boolean) => {
        filter[type] = value;
        onChange(filter);
    }

    const getCareers = () => {
        const careers: CompanyCareer[] = [];
        for (const company of companies) {
            for (const career of company.career) {
                const find = careers.find((car) =>
                    car.name.trim().toLowerCase() === career.name.trim().toLowerCase());

                if (!find) careers.push(career);
            }
        }
        return careers;
    }

    const resetFilter = () => {
        onChange(undefined);
    }

    return (<>
        <div className="filter-content">
            <Form layout="vertical">
                <Space>
                    <Form.Item label="Ausbildung">
                        <Switch value={filter.educations} onChange={(val) => onSwitchChange("educations", val)} />
                    </Form.Item>

                    <Form.Item label="Studium">
                        <Switch value={filter.studies} onChange={(val) => onSwitchChange("studies", val)} />
                    </Form.Item>

                    <Form.Item label="Schulisch">
                        <Switch value={filter.school} onChange={(val) => onSwitchChange("school", val)} />
                    </Form.Item>
                </Space>

                <Form.Item label="Branche">
                    <Select
                        id="select_branche"
                        mode="multiple"
                        allowClear
                        size="large"
                        style={{width: 350}}
                        onChange={onBranchesChange}
                    >
                        {
                            branches.map((branch) => {
                                return <Select.Option key={"branch_" + branch.id}>{branch.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item label="Angebote (m/w/d)">
                    <Select
                        id="select_type"
                        mode="multiple"
                        allowClear
                        size="large"
                        style={{width: 350}}
                        onChange={onCareerChange}
                    >
                        { getCareers().map((career) => {
                            return <Select.Option key={"career_" + career.name}>{career.name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
            </Form>
            <Button onClick={() => resetFilter()}>
                Suche Zurücksetzen
            </Button>
        </div>
        <div className={"filter-companies"}>
            <Typography.Title level={4}>Unternehmen A-Z</Typography.Title>
            <Select
                id="select_company"
                mode="multiple"
                allowClear
                size={"large"}
                style={{ width: 350 }}
                onChange={onCompanyChange}
            >
                { companies.map((comp) => {
                    return <Select.Option key={"company_" + comp.name}>
                        {comp.name}
                    </Select.Option>
                })}
            </Select>
        </div>
    </>);
}
