import { Company } from "../interfaces/company";


export class FilterModel {
    companies: string[] = [];
    branches: string[] = [];
    careers: string[] = [];
    school: boolean = false;
    educations: boolean = false;
    studies: boolean = false;
}
